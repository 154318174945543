import { isAfter } from 'date-fns'
import { getAgeFromBirthDate, isValidDate, parseDate, reformatDate } from '@core/common'
import { NaturePret, PretFormUI, SourcePret } from './PretFormUI'
import {
  ALL_NATURE_PRET,
  ALL_REGIME_PRET,
  ALL_SOURCE_PRET, MAX_DAY_UNTIL_FIN,
  MAX_MONTANT_TOTAL, MAX_TAUX,
  MIN_DATE_FROM_BIRTHDATE,
  MIN_DAY_UNTIL_FIN, MIN_INPUT_VALUE,
  PRET_DE_RESTRUCTURATION,
  PRET_IMMOBILIER,
} from './const'
import { ValidationFieldError } from '../ValidationFieldError'
import { DATE_MM_YYYY, DATE_YYYY_MM_DD } from '../format'
import { EncoursUI } from './EncoursUI'

export function validatePret(
  { repris, nature, nomPreteur, debut, fin, regime, taux, montantCrd, montantIra, montantCapitalInitial,
    paliers, preavis, sourceTaux, sourcePreavis, sourceIra, sourceFin, finalite }: PretFormUI,
  gamme: string,
  currentdate: string,
  encoursUI: EncoursUI) {
  const avecGarantie = gamme === 'SECURED'
  const errors: ValidationFieldError[] = []
  const montantTotal = (montantCrd || 0) + (montantIra || 0)
  const [firstPalier] = paliers || []
  const numberValueFields = [
    { value: montantCrd, field: 'montantCrd' },
    { value: montantIra, field: 'montantIra' },
    { value: montantCapitalInitial, field: 'montantCapitalInitial' },
    { value: taux, field: 'taux' },
    { value: preavis, field: 'preavis' },
  ]

  if (!nature) {
    errors.push({ field: 'nature', message: 'MISSING_VALUE' })
  }

  if (!nomPreteur) {
    errors.push({ field: 'nomPreteur', message: 'MISSING_VALUE' })
  }

  if (!debut && nature && PRET_DE_RESTRUCTURATION.includes(nature)) {
    errors.push({ field: 'debut', message: 'MISSING_VALUE' })
  }

  if (!montantCrd && montantCrd !== MIN_INPUT_VALUE) {
    errors.push({ field: 'montantCrd', message: 'MISSING_VALUE' })
  }

  numberValueFields
    .filter(({ value }) => !!value && value < MIN_INPUT_VALUE)
    .forEach(({ field }) => errors.push({ field, message: 'NEGATIVE_VALUE' }))

  if (!!paliers?.[0]?.montantEcheance && paliers[0].montantEcheance < MIN_INPUT_VALUE) {
    errors.push({ field: 'montantEcheance', message: 'NEGATIVE_VALUE' })
  }

  if (!regime) {
    errors.push({ field: 'regime', message: 'MISSING_VALUE' })
  }

  if ((!firstPalier?.montantEcheance && firstPalier?.montantEcheance !== MIN_INPUT_VALUE) && nature !== NaturePret.DECOUVERT) {
    errors.push({ field: 'montantEcheance', message: 'MISSING_VALUE' })
  }

  if (debut && !isValidDate(debut, DATE_MM_YYYY)) {
    errors.push({ field: 'debut', message: 'DATE_FORMAT_INVALID' })
  }

  if (fin) {
    if (!isValidDate(fin, DATE_MM_YYYY)) {
      errors.push({ field: 'fin', message: 'DATE_FORMAT_INVALID' })
    } else if (!isAfter(parseDate(fin, DATE_MM_YYYY)!, parseDate(currentdate, DATE_MM_YYYY)!)) {
      errors.push({ field: 'fin', message: 'DATE_END_INVALID' })
    }
  }

  if (!fin && sourceFin !== SourcePret.NON_COMMUNIQUE && nature !== NaturePret.DECOUVERT) {
    errors.push({ field: 'fin', message: 'MISSING_VALUE' })
  }

  if (nature && !ALL_NATURE_PRET.includes(nature)) {
    errors.push({ field: 'nature', message: 'WRONG_VALUE' })
  }

  if (regime && !ALL_REGIME_PRET.includes(regime)) {
    errors.push({ field: 'regime', message: 'WRONG_VALUE' })
  }

  if (sourceTaux && !ALL_SOURCE_PRET.includes(sourceTaux)) {
    errors.push({ field: 'sourceTaux', message: 'WRONG_VALUE' })
  }

  if (sourcePreavis && !ALL_SOURCE_PRET.includes(sourcePreavis)) {
    errors.push({ field: 'sourcePreavis', message: 'WRONG_VALUE' })
  }

  if (sourceIra && !ALL_SOURCE_PRET.includes(sourceIra)) {
    errors.push({ field: 'sourceIra', message: 'WRONG_VALUE' })
  }

  if (sourceFin) {
    if (!ALL_SOURCE_PRET.includes(sourceFin)) {
      errors.push({ field: 'sourceFin', message: 'WRONG_VALUE' })
    }
  }

  if (finalite) {
    if (nature && !PRET_IMMOBILIER.includes(nature)) {
      errors.push({ field: 'finalite', message: 'WRONG_NATURE' })
    }
    if (repris) {
      errors.push({ field: 'finalite', message: 'WRONG_RACHAT' })
    }
    if (avecGarantie && !encoursUI.hasPretOrSoulteRepris()) {
      errors.push({ field: 'finalite', message: 'NO_INVEST' })
    }
  }

  if (nature && [NaturePret.CREDIT_RENOUVELABLE, NaturePret.DECOUVERT].includes(nature)) {
    if (montantIra) {
      errors.push({ field: 'montantIra', message: 'WRONG_NATURE' })
    }
    if (preavis) {
      errors.push({ field: 'preavis', message: 'WRONG_NATURE' })
    }
  }

  const dateDebut = parseDate(debut, DATE_MM_YYYY)
  if (dateDebut && isAfter(dateDebut, new Date())) {
    errors.push({ field: 'debut', message: 'AFTER_TODAY' })
  }
  if (taux && (Number(taux) < MIN_INPUT_VALUE || Number(taux) > MAX_TAUX)) {
    errors.push({ field: 'taux', message: 'WRONG_TAUX' })
  }
  if ((nature === NaturePret.PRET_TAUX_ZERO_HYPOTHECAIRE || nature === NaturePret.PRET_TAUX_ZERO_NON_HYPOTHECAIRE)) {
    if (taux && taux !== MIN_INPUT_VALUE) {
      errors.push({ field: 'taux', message: 'TAUX_ZERO' })
    }
    if (montantIra && montantIra !== MIN_INPUT_VALUE) {
      errors.push({ field: 'montantIra', message: 'TAUX_ZERO' })
    }
  }
  if (getAgeFromBirthDate(reformatDate(debut, DATE_MM_YYYY, DATE_YYYY_MM_DD)) < MIN_DATE_FROM_BIRTHDATE) {
    if (avecGarantie) {
      if (nature === NaturePret.PRET_DE_RESTRUCTURATION_HYPOTHECAIRE) {
        errors.push({ field: 'debut', message: 'TOO_RECENT' })
      }
    } else if (repris && montantTotal >= MAX_MONTANT_TOTAL && nature === NaturePret.PRET_DE_RESTRUCTURATION_NON_HYPOTHECAIRE) {
      errors.push({ field: 'debut', message: 'TOO_RECENT' })
    }
  }

  if (!repris && nature === NaturePret.PRET_DE_RESTRUCTURATION_NON_HYPOTHECAIRE && !avecGarantie) {
    errors.push({ field: 'nature', message: 'CANT_KEEP_UNSECURED_CONSO' })
  }

  if (nature === NaturePret.PRET_DE_RESTRUCTURATION_HYPOTHECAIRE && !avecGarantie) {
    errors.push({ field: 'nature', message: 'NO_UNSECURED_RESTRUCTURATION_HYPOTHECAIRE' })
  }

  if (!repris && nature === NaturePret.DECOUVERT) {
    errors.push({ field: 'nature', message: 'CANT_KEEP' })
  }

  return errors
}

export function checkWarningsPret(pretFormUI: PretFormUI) {
  if (pretFormUI.fin && isValidDate(pretFormUI.fin, DATE_MM_YYYY)) {
    const daysUntilFin = Math.ceil((parseDate(pretFormUI.fin, DATE_MM_YYYY)!.getTime() - new Date().getTime()) / (1000 * 3600 * 24))
    if (daysUntilFin > MIN_DAY_UNTIL_FIN && daysUntilFin < MAX_DAY_UNTIL_FIN) {
      return [{ field: 'fin', message: 'THE_END_IS_NIGH' }]
    }
  }
  return []
}
