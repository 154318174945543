import React from 'react'
import classNames from 'classnames'
import { func, oneOf } from 'prop-types'
import { capitalize } from '@core/common'

import { ScrollList } from '@core/partenaire-ui'
import { ROLES } from '@core/partenaire-common'

import { MoreActions } from '../MoreActions'
import { usePersonnes } from './List.hooks'
import { shouldHandleAgences } from '../../utils/const'
import { CellHiglightingEmptyValue } from './CellHiglightingEmptyValue'
import { CellStatut } from './CellStatut'

import styles from './List.module.css'

List.propTypes = {
  updateTotalCount: func.isRequired,
  nomenclature: oneOf(['PARTENAIRE', 'PORTAIL_CGP', 'MAP_DOM']).isRequired,
}

const PORTAIL_CGP_NOMENCLATURE = {
  name: 'Nom',
  surname: 'Prénom',
  email: 'Email',
  cabinet: 'Cabinet',
  apr: 'Agence',
  role: 'Rôle',
  status: 'Statut',
}

const PARTENAIRE_NOMENCLATURE = {
  ...PORTAIL_CGP_NOMENCLATURE,
  cabinet: 'Partenaire',
}

export function List({ updateTotalCount, nomenclature }) {
  const { displayedPersonnes, counters, fetchMore, listRef, hasNextPage, sort } = usePersonnes(updateTotalCount)
  const CURRENT_NOMENCLATURE = (nomenclature === 'PARTENAIRE' || nomenclature === 'MAP_DOM') ? PARTENAIRE_NOMENCLATURE : PORTAIL_CGP_NOMENCLATURE

  const applyActiveClassName = (sortName, columnName) => {
    return classNames({ [styles.list__headerActive]: sortName === columnName })
  }

  const showAgences = shouldHandleAgences()

  const header = (
    <div role="row" className={classNames(styles.list__header, showAgences ? styles.list__lineWithAgences : styles.list__lineWithoutAgences)}>
      <span role="columnheader" className={applyActiveClassName(sort, 'nom')}>{CURRENT_NOMENCLATURE.name}</span>
      <span role="columnheader" className={applyActiveClassName(sort, 'prenom')}>{CURRENT_NOMENCLATURE.surname}</span>
      <span role="columnheader" className={applyActiveClassName(sort, 'email')}>{CURRENT_NOMENCLATURE.email}</span>
      <span role="columnheader">{CURRENT_NOMENCLATURE.cabinet}</span>
      {showAgences && <span role="columnheader">{CURRENT_NOMENCLATURE.apr}</span>}
      <span role="columnheader" className={applyActiveClassName(sort, 'role')}>{CURRENT_NOMENCLATURE.role}</span>
      <span role="columnheader" className={applyActiveClassName(sort, 'statut')}>{CURRENT_NOMENCLATURE.status}</span>
    </div>
  )

  return (
    <ScrollList
      rowClassName={styles.list__line}
      listref={listRef}
      hasNextPage={hasNextPage}
      totalCount={counters?.total}
      loadMore={fetchMore}
      customScrollbar
      header={header}
      ariaLabel="Utilisateur"
    >
      {displayedPersonnes.map(personne => {
        const { id, nom, prenom, email, cabinets, agences, statut } = personne
        const index = displayedPersonnes.findIndex(({ id: pId }) => pId === id)
        const cabinetsFormatted = [...new Set(cabinets.map(({ libelle }) => libelle))].join(', ')
        const idsCabinet = cabinets.map(({ id: idCabinet }) => idCabinet)
        const agencesFormatted = agences.join(', ')
        const personneRoles = cabinets.map(({ role }) => role)
        const roleOption = ROLES.find(r => personneRoles.includes(r.value))
        const role = roleOption?.value
        const roleFormatted = roleOption?.label
        const ariaLabel = `Utilisateur ${(!nom || !prenom) ? email : `${nom} ${prenom}`}`
        return (
          <div
            key={id}
            role="row"
            aria-label={ariaLabel}
            className={classNames(
              styles.list__line, { [styles.list__lineStriped]: index % 2 !== 0 }, showAgences ? styles.list__lineWithAgences : styles.list__lineWithoutAgences)}
          >
            <CellHiglightingEmptyValue value={nom?.toUpperCase() || ''} />
            <CellHiglightingEmptyValue value={capitalize(prenom)} />
            <span className={styles.list__cell} title={email} role="cell">{email}</span>
            <span className={styles.list__cell} title={cabinetsFormatted} role="cell">{cabinetsFormatted}</span>
            {showAgences && <span className={styles.list__cell} title={agencesFormatted} role="cell">{agencesFormatted}</span>}
            <span className={classNames(styles.list__cell, styles.list__role)} title={roleFormatted} role="cell">{roleFormatted}</span>
            <CellStatut statut={statut} />
            <MoreActions id={id} email={email} role={role} cabinets={idsCabinet} agences={agences} />
          </div>
        )
      })}
    </ScrollList>
  )
}
