import { EncoursUI, buildValidationError } from '../../model'
import { Demande } from '../../model/demande'
import { TypeAmortissement } from '../../model/demande/Demande'

interface ParsedDemande {
  typeAmortissement?: TypeAmortissement
  dureeFinancement?: number
  fraisMandat?: number
  apport?: number
  tresorerie: {
    travaux?: number
    confort?: number
    autre?: number
  }
}

const isWithinInterval = (duree: number, min: number, max: number) => !(duree < min || duree > max)

function validateDemandeParsed({
  demande: {
    typeAmortissement,
    dureeFinancement: duree,
    fraisMandat,
    apport,
    tresorerie,
  },
  isSecured,
  hasBaisseRevenu,
  encours,
}:{
  demande: ParsedDemande
  isSecured: boolean
  hasBaisseRevenu: boolean
  encours: EncoursUI | undefined
}) {
  const globalErrors: { message: string, field: string }[] = []
  if (duree !== 0 && !duree) globalErrors.push(buildValidationError('dureeFinancement', 'MISSING_VALUE'))
  if (!!duree && duree < 0) globalErrors.push(buildValidationError('dureeFinancement', 'NEGATIVE_VALUE'))
  if (!typeAmortissement) globalErrors.push(buildValidationError('amortissement', 'MISSING_VALUE'))
  if (fraisMandat !== 0 && !fraisMandat) globalErrors.push(buildValidationError('fraisMandat', 'MISSING_VALUE'))
  if (!!fraisMandat && fraisMandat < 0) globalErrors.push(buildValidationError('fraisMandat', 'NEGATIVE_VALUE'))

  if (duree) {
    if (!isSecured && !isWithinInterval(duree, 60, 180)) {
      globalErrors.push({ field: 'dureeFinancement', message: 'OUT_OF_BOUNDS' })
    }
    if (isSecured && !isWithinInterval(duree, 120, 300)) {
      globalErrors.push({ field: 'dureeFinancement', message: 'OUT_OF_BOUNDS' })
    }
    if (Object.values(tresorerie).reduce((accumulator: number, tresorerieValue: number) => accumulator +
      tresorerieValue) < 1000) globalErrors.push({ field: 'tresorerie', message: 'UNDER_LIMITS' })
  }
  if (hasBaisseRevenu && typeAmortissement === TypeAmortissement.LINEAIRE) {
    globalErrors.push({ field: 'typeAmortissement', message: 'WITHOUT_PALIER_WITH_BAISSE_DE_REVENU' })
  }
  if (!hasBaisseRevenu && typeAmortissement === TypeAmortissement.PALIER) {
    globalErrors.push({ field: 'typeAmortissement', message: 'WITH_PALIER_WITHOUT_BAISSE_DE_REVENU' })
  }
  if (apport && !isSecured) {
    globalErrors.push({ field: 'apport', message: 'UNSECURED' })
  }
  if (apport && isSecured) {
    if (!encours?.hasPretOrSoulteRepris()) {
      globalErrors.push({ field: 'apport', message: 'SECURED_NEW_TRESO' })
    }
  }
  if (!!encours && isSecured && !!tresorerie?.travaux && tresorerie.travaux > 75_000) {
    if (!encours.hasPretsRepris() && !encours.hasDettesRepris()) {
      globalErrors.push({ field: 'travaux', message: 'OVER_LIMITS_MY_NEW_TRESO' })
    }
    if (encours.getPretsRepris().length === 1 && encours.getDettesRepris().length === 0) {
      globalErrors.push({ field: 'travaux', message: 'OVER_LIMITS_MSO' })
    }
  }

  if (!isSecured && Object.values(tresorerie).reduce((accumulator: number, tresorerieValue: number) => accumulator + tresorerieValue) > 50_000) {
    globalErrors.push({ field: 'tresorerie', message: 'OVER_LIMITS_UNSECURED' })
  }

  if (!!tresorerie?.confort && tresorerie?.confort < 0) {
    globalErrors.push({ field: 'confort', message: 'NEGATIVE_VALUE' })
  }
  if (!!tresorerie?.travaux && tresorerie?.travaux < 0) {
    globalErrors.push({ field: 'travaux', message: 'NEGATIVE_VALUE' })
  }
  if (!!tresorerie?.autre && tresorerie?.autre < 0) {
    globalErrors.push({ field: 'autre', message: 'NEGATIVE_VALUE' })
  }

  return {
    globalErrors,
    hasErrors: globalErrors.length > 0,
  }
}

export function toDemandeParsed(demandeForm: Demande): ParsedDemande {
  return {
    dureeFinancement: demandeForm.dureeFinancement,
    fraisMandat: demandeForm.fraisMandat,
    apport: demandeForm.apport,
    typeAmortissement: demandeForm.typeAmortissement,
    tresorerie: {
      confort: demandeForm.tresorerie?.confort,
      travaux: demandeForm.tresorerie?.travaux,
      autre: demandeForm.tresorerie?.autre,
    },
  }
}

export function validateDemande({ demande, isSecured, hasBaisseRevenu, encours }:
  { demande: Demande, isSecured: boolean, hasBaisseRevenu: boolean, encours: EncoursUI }) {
  return validateDemandeParsed({ demande: toDemandeParsed(demande), isSecured, hasBaisseRevenu, encours })
}
