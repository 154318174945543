import React, { useState, useEffect, FunctionComponent } from 'react'
import cn from 'classnames'
import { useDebounce } from 'use-debounce'
import { Input, MagnifyingGlass } from '@mmb/ui-components'

import styles from './InputSearch.module.css'

interface InputSearchProps {
  onDebouncedChange?: any
  onChange?: any
  className?: string
  labelClassName?: string
  fieldClassName?: string
  iconClassName?: string
  name?: string
  label?: string
  placeholder?: string
}

export const InputSearch: FunctionComponent<InputSearchProps> = ({
  onDebouncedChange, onChange, className, labelClassName, fieldClassName, iconClassName, name, label, placeholder, ...props
}) => {
  const [value, setValue] = useState('')

  const [debounceValue] = useDebounce(value, 500)

  useEffect(() => {
    onDebouncedChange(debounceValue)
  }, [debounceValue])

  const inputOnChange = e => {
    onChange(e.target.value)
    setValue(e.target.value)
  }

  return (
    <Input
      value={value}
      className={cn(styles.inputSearch__input, className)}
      labelClassName={cn(styles.inputSearch__label, labelClassName)}
      fieldClassName={cn(styles.inputSearch__field, fieldClassName)}
      onChange={inputOnChange}
      name={name}
      label={label}
      placeholder={placeholder}
      {...props}
    >
      <MagnifyingGlass className={cn(styles.inputSearch__icon, iconClassName)} />
    </Input>
  )
}

InputSearch.defaultProps = {
  onDebouncedChange: () => null,
  onChange: () => null,
  className: '',
  labelClassName: '',
  fieldClassName: '',
  iconClassName: '',
  name: undefined,
  label: undefined,
  placeholder: undefined,
}
