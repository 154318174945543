import React from 'react'
import classNames from 'classnames'
import { bool, func, oneOf, string, any } from 'prop-types'
import { Chevron } from '@mmb/ui-components'

import styles from './SortableColumnHeader.module.css'

SortableColumnHeader.propTypes = {
  title: string,
  titlePosition: oneOf(['left', 'center', 'right']),
  className: string,
  sorted: bool,
  descendingOrder: bool,
  onClick: func,
  chevron: any,
  chevronStyle: string,
}

SortableColumnHeader.defaultProps = {
  title: '',
  titlePosition: 'left',
  className: undefined,
  sorted: false,
  descendingOrder: false,
  onClick: undefined,
  chevron: undefined,
  chevronStyle: undefined,
}

/**
 * En-tête de colonne triable. Le titre s'affiche en gras si le tri est sur cette colonne, et une flèche donne le sens du tri.
 * @param title           titre de la colonne
 * @param titlePosition   position du titre
 * @param className       classe de style à appliquer
 * @param sorted          flag indiquant si le tri est actif
 * @param descendingOrder flag indiquant si le tri est descendant
 * @param onClick         fonction à appeler en cas de clic
 */
export function SortableColumnHeader({ title, titlePosition, className, sorted, descendingOrder, onClick, chevron, chevronStyle }) {
  const cn = classNames(
    className,
    styles.header,
    {
      [styles.headerSorted]: sorted,
      [styles.headerUnclickable]: !onClick,
      [styles.headerCenter]: titlePosition === 'center',
      [styles.headerRight]: titlePosition === 'right',
    },
  )

  const ChevronComponent = chevron ?? Chevron

  return (
    <a role="columnheader" className={cn} href="" onClick={onClick ?? (event => event.preventDefault())}>
      <span>{title}</span>
      {sorted && <ChevronComponent direction={descendingOrder ? 'up' : 'down'} className={classNames(styles.header__sortIndicator, chevronStyle)} />}
    </a>
  )
}
