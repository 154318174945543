import React from 'react'
import { useParams } from '@core/routing'
import { Spinner } from '@mmb/ui-components'
import { CopieDossierIcon } from '@partenaire/frontend-ui'
import styles from './CopieDossier.module.css'
import { useCopyDossier } from './CopieDossier.hook'

export function CopieDossier() {
  const { id } = useParams()
  const { copyDossier, loading } = useCopyDossier(id!)

  return (
    <div className={styles.copie}>
      {loading ? <Spinner /> : (
        <>
          <CopieDossierIcon />
          <div className={styles.textcontainer}>
            <span className={`${styles.bouton} ${styles.textcontent}`} onClick={copyDossier}>Créer un nouveau dossier sous une autre gamme</span>
            <span className={styles.textcontent}>en dupliquant les données</span>
          </div>
        </>
      )}
    </div>
  )
}
