import React, { FunctionComponent } from 'react'

import { FromPage, trackOnClick } from '@core/analytics'
import { GammeButton } from './GammeButton'
import styles from './GammeSelector.module.css'

export interface Gamme {
  label: string
  value: string
  colorClassName?: string
}

interface GammeSelectorProps {
  className?: string
  selectedGamme: string | undefined
  setSelectedGamme: (gamme: string | undefined) => void
  gammes: Gamme[]
}

export const GammeSelector: FunctionComponent<GammeSelectorProps> = ({ className, selectedGamme, gammes, setSelectedGamme }) => {
  return (
    <div className={className}>
      <h4 className={styles.gammes__title}>Gammes</h4>
      <div className={styles.gammes__buttons}>
        {gammes.map(gamme => {
          return (
            <GammeButton
              key={gamme.value}
              className={styles.gammes__gamme}
              gammeClasseName={gamme.colorClassName}
              isClicked={selectedGamme === gamme.value}
              onClick={() => {
                trackOnClick('filtre gammes', FromPage.DOSSIERS, gamme.value)
                setSelectedGamme(gamme.value)
              }}
              onClose={() => setSelectedGamme(undefined)}
              testId={gamme.value}
            >
              {gamme.label}
            </GammeButton>
          )
        })}
      </div>
    </div>
  )
}

GammeSelector.defaultProps = {
  className: '',
}
