import { gql, useMutation } from '@core/graphql'
import { toast } from '@core/ui'
import { useNavigate } from 'react-router-dom'
import { getDossierRedirectPath } from '@partenaire/frontend-common'
import { DossierStatut } from '@partenaire/common'

const COPIE_DOSSIER = gql`
mutation copyDossierFromId($id: ID!) {
  partenaire_copyDossierFromId(id: $id)
}
`
export function useCopyDossier(id: string) {
  const navigate = useNavigate()
  const [copyDossier, { loading }] = useMutation(COPIE_DOSSIER, {
    onCompleted: data => {
      const newId = data?.partenaire_copyDossierFromId
      toast(newId ? { message: `Le dossier a bien été copié (nouveau dossier ${newId}).`, type: 'SUCCESS' } :
        { message: `Le dossier ${id} n'a pas été copié, une erreur s'est produite.`, type: 'ERROR' })
      if (newId) { navigate(getDossierRedirectPath(newId, DossierStatut.SAISIE, true)) }
    },
    onError: () => {
      toast({ message: `Erreur interne lors de la copie du dossier ${id}.`, type: 'ERROR' })
    },
  })

  return {
    copyDossier: () => copyDossier({ variables: { id } }),
    loading,
  }
}
