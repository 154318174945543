import React from 'react'
import classNames from 'classnames'
import { useMatch } from 'react-router-dom'
import { func, string } from 'prop-types'
import { FromPage, trackOnClick } from '@core/analytics'

import { Link } from '@core/ui'

import styles from './DetailPanel.module.css'

DetailPanelLink.propTypes = {
  linkPattern: string.isRequired,
  label: string.isRequired,
  replaceReference: func.isRequired,
}

export function DetailPanelLink({ linkPattern, label, replaceReference }) {
  return (
    <Link
      activeclassname={styles.detailPanel__linkActive}
      className={classNames(styles.detailPanel__link, { [styles.detailPanel__linkActive]: useMatch(linkPattern) })}
      to={replaceReference(linkPattern)}
      key={replaceReference(linkPattern)}
      onClick={() => trackOnClick('step vertical', FromPage.DETAIL, label)}
    >
      {label}
    </Link>
  )
}
