
import React, { useEffect, useRef, useState } from 'react'
import { useReactToPrint } from 'react-to-print'
import { FromPage, trackOnClick } from '@core/analytics'

import { Button, Download as DownloadIcon } from '@mmb/ui-components'
import { string } from 'prop-types'
import { ToPrintDocument } from './ToPrintDocument'

DownloadButton.propTypes = {
  from: string,
}

DownloadButton.defaultProps = {
  from: string,
}

export function DownloadButton({ from }) {
  const componentRef = useRef(null)

  const [isDisable, setIsDisable] = useState(true)

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  useEffect(() => {
    if (!isDisable) {
      handlePrint()
      setIsDisable(true)
    }
  }, [isDisable])

  const startPrinting = () => {
    trackOnClick(`télécharger le dossier ${from === 'simulation' ? 'après simu' : 'avant simu'}`, FromPage.SAISIE, 'dossier.pdf ')
    setIsDisable(false)
  }

  return (
    <>
      <Button leftIcon={DownloadIcon} onClick={startPrinting} outline> Télécharger le dossier </Button>
      <div ref={componentRef}>
        {!isDisable && <ToPrintDocument />}
      </div>
    </>
  )
}
