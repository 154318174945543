import React from 'react'
import classnames from 'classnames'
import { bool, number, string } from 'prop-types'

import { Input } from '@mmb/ui-components'

import styles from './InputMontant.module.css'

InputMontant.propTypes = {
  readOnly: bool,
  className: string,
  fieldClassName: string,
  noBorder: bool,
  unit: string,
  step: number,
  hideLabel: bool,
  unitClassName: string,
  precision: number,
}

InputMontant.defaultProps = {
  readOnly: false,
  className: '',
  fieldClassName: '',
  noBorder: false,
  unit: '€/mois',
  step: 1,
  hideLabel: false,
  unitClassName: '',
  precision: 2,
}

export function InputMontant({
  readOnly = false,
  noBorder = false,
  className = '',
  fieldClassName = '',
  step = 1,
  unit = '€/mois',
  hideLabel = false,
  unitClassName = '',
  precision = 2,
  ...props }) {
  return (
    <Input
      className={classnames(className, styles.inputMontant)}
      fieldClassName={classnames(fieldClassName, { [styles.inputMontant__inputDisabled]: readOnly, [styles.inputMontant__inputNoBorder]: noBorder })}
      type="number"
      step={step}
      min="0"
      precision={precision}
      readOnly={readOnly}
      hideLabel={hideLabel}
      {...props}
    >
      <span className={classnames(unitClassName, styles.inputMontant__unit)}>
        {unit}
      </span>
    </Input>
  )
}
