import React from 'react'
import { useParams } from '@core/routing'
import { ActionBoard } from '@partenaire/frontend-ui'
import { DossierStatut } from '@partenaire/common'

import { DetailAssurance } from './DetailAssurance'
import { DetailHistorique } from './DetailHistorique'
import { DetailGestionDePret } from './DetailGestionDePret'
import { useDossier } from './Dossier.hooks'
import styles from './Detail.module.css'
import { DetailLayout } from '../Detail/DetailLayout'
import { PlanFinancement } from './PlanFinancement'
import { InformationMessage } from '../InformationMessage'

import { useActions } from './DetailActions'
import { CopieDossier } from '../CopieDossier'

export function Detail() {
  const { id } = useParams()
  const { error, loading, nomProduit, montant, gamme, jourPrelevement, notaire, dateDeblocage, isAnticipe, statut }
    = useDossier(id)
  const actions = useActions()
  const tableauStatuts = [DossierStatut.REFUSE, DossierStatut.REFUSE_PREFILTRAGE, DossierStatut.SANS_SUITE,
    DossierStatut.SANS_SUITE_PREFILTRAGE]
  if (loading) return null
  return (
    <DetailLayout
      id={id}
      title="Détail dossier"
    >
      <div className={styles.detail__content}>
        {error && <InformationMessage message="Non communiqué" title="Plan de financement" />}
        {!error && <PlanFinancement id={id} montant={montant} nomProduit={nomProduit} gamme={gamme} />}
        <DetailAssurance id={id} />
        <DetailGestionDePret
          gamme={gamme}
          jourPrelevement={jourPrelevement}
          notaire={notaire}
          dateDeblocage={dateDeblocage}
          isAnticipe={isAnticipe}
        />
      </div>
      {gamme && (
        <div className={styles.detail__historique}>
          {(tableauStatuts.includes(statut)) && <CopieDossier />}
          <ActionBoard actions={actions} />
          <DetailHistorique id={id} gamme={gamme} />
        </div>
      )}
    </DetailLayout>
  )
}
