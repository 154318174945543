/* eslint-disable react/require-default-props */
import React, { FunctionComponent } from 'react'
import Select from 'react-select'

import styles from './SelectFilter.module.css'

interface SelectFilterProps {
  options: {
    value?: string
    label?: string
  }[]
  defaultValue?: {
    value?: string
    label?: string
  }
  onSelect: any
  name: string
  placeholder: string
  colourStyles?: {
    control: any
    input: any
    multiValue: any
    multiValueLabel: any
    multiValueRemove: any
    dropdownIndicator: any
    clearIndicator: any
    indicatorSeparator: any
  }
}

/** Permet de définir tout le style du select */
const defaultColourStyles = {
  control: base => ({ ...base, backgroundColor: '#13294B', borderRadius: 0 }),
  input: base => ({ ...base, color: 'white' }),
  multiValue: base => {
    return {
      ...base,
      backgroundColor: 'white',
    }
  },
  multiValueLabel: base => ({
    ...base,
    color: '#13294B',
  }),
  multiValueRemove: base => ({
    ...base,
    color: '#13294B',
  }),
  dropdownIndicator: base => ({
    ...base,
    color: 'white',
  }),
  clearIndicator: base => ({
    ...base,
    color: 'white',
  }),
  indicatorSeparator: base => ({
    ...base,
    backgroundColor: 'white',
  }),
}

export const SelectFilter : FunctionComponent<SelectFilterProps> = ({
  options = [{ value: '', label: '' }],
  onSelect = () => {},
  name = '',
  placeholder = 'Sélectionnez...',
  defaultValue = [],
  colourStyles = defaultColourStyles,
}) => {
  const selectOnChange = elements => {
    if (elements !== null && elements.length > 0) {
      onSelect(elements.map(element => element.value))
    } else {
      onSelect([])
    }
  }
  return (
    <Select
      id={name}
      name={name}
      className={styles.selectFilter}
      styles={{ ...defaultColourStyles, ...colourStyles }}
      isMulti
      defaultValue={defaultValue}
      options={options}
      onChange={selectOnChange}
      placeholder={placeholder}
    />
  )
}
