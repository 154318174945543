import React from 'react'
import { bool, func, shape, string } from 'prop-types'

import { Button } from '@mmb/ui-components'
import { FromPage, trackOnClick } from '@core/analytics'
import { SelectCommunes, useCommunesOptions } from '@partenaire/frontend-ui'

import styles from './Formulaire.module.css'

Formulaire.propTypes = {
  form: shape({
    codePostal: string,
    codeInsee: string,
    cpVille: string,
  }).isRequired,
  setShowResult: func.isRequired,
  setForm: func.isRequired,
  showResult: bool,
}

Formulaire.defaultProps = {
  showResult: undefined,
}

const formatCommune = ({ nom, codePostal, codeInsee }) => ({ label: `${codePostal} ${nom}`, value: JSON.stringify({ codePostal, codeInsee }) })

export function Formulaire({ form, showResult, setShowResult, setForm }) {
  const { setCodePostalVille, citiesOptions } = useCommunesOptions(formatCommune)

  const onChange = ({ label, value }) => {
    const { codePostal, codeInsee } = JSON.parse(value)
    setForm({
      codePostal,
      codeInsee,
      cpVille: label,
    })
  }

  return (
    <form
      onSubmit={async e => {
        trackOnClick('boutton RH je teste', FromPage.ACCUEIL, 'test du ratio hypothécaire')
        e.preventDefault()
        setShowResult(true)
      }}
    >
      <SelectCommunes
        setCodePostalVille={setCodePostalVille}
        citiesOptions={citiesOptions}
        onChange={onChange}
        autoFocus={showResult === false}
        required={form.cpVille === ''}
        name="cpVille"
        label="Code postal ou ville"
        hideLabel
        hideDropdown
      />
      <Button
        type="submit"
        aria-label="Test du ratio hypothecaire"
        outline
        className={styles.formulaire__button}
      >
        Je teste
      </Button>
    </form>
  )
}
