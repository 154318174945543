import React, { useState } from 'react'
import { node, func, string } from 'prop-types'
import { modalContext } from '../../../contexts/modalContext'

import { Modal } from '../Modal'

ModalTrigger.propTypes = {
  renderButton: func.isRequired,
  className: string,
  children: node,
  onClose: func,
}

ModalTrigger.defaultProps = {
  className: '',
  children: null,
  onClose: () => {},
}

export function ModalTrigger({ renderButton, className = '', children, onClose = () => {}, ...props }) {
  const [isOpen, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
    onClose()
  }

  return (
    <modalContext.Provider value={{ isOpen, setOpen }}>
      {renderButton(isOpen, setOpen)}

      <Modal
        className={className}
        isOpen={isOpen}
        onClose={handleClose}
        {...props}
      >
        {children}
      </Modal>
    </modalContext.Provider>
  )
}
