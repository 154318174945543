import { PASSWORD_REGEXP } from '@core/common'

export const PASSWORD_VALIDATION = {
  regexp: PASSWORD_REGEXP,
  message: 'Le mot de passe doit comporter au moins 12 caractères et doit inclure au moins 3 des 4 caractères suivants : ' +
    'une minuscule, une majuscule, un chiffre et un caractère spécial.',
}

const isTestEnv = process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'mock'

const getAppId = () => {
  let appId = '#___gatsby'
  if (isTestEnv) {
    appId = document.createElement('div')
  }
  return appId
}

export const APP_ELEMENT_ID = getAppId()
