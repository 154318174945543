/* eslint-disable react/require-default-props */
import React, { FunctionComponent } from 'react'
import { default as ReactSelect, createFilter } from 'react-select'
import classnames from 'classnames'

import styles from './Select.module.css'

interface SelectPropType {
  className?: string
  labelClassName?: string
  fieldClassName?: string
  label?: string
  options?: {
    value?: string
    label?: string
  }[]
  onChange?: any
  name?: string
  defaultValue?: any
  placeholder?: string
  isMulti?: boolean
  required?: boolean
  disabled?: boolean
  value?: any
  hideLabel?: boolean
  hideEmptyMenu?: boolean
  hideDropdown?: boolean
  hasError?: boolean
  filterConfig?: {
    ignoreCase?: boolean
    ignoreAccents?: boolean
    matchFrom?: 'start' | 'any'
    stringify?: any
    trim?: boolean
  }
  htmlFor?: string
  id?: string
}

const styleOption = (isFocused, isSelected) => {
  const colorSecondary = isFocused || isSelected

  return {
    color: colorSecondary ? '#00a9e0' : '#13294B',
    fontSize: '1.4rem',
    minHeight: '4.8rem',
    borderRadius: 0,
    paddingLeft: '1rem',
    background: '#fff',
  }
}

const getStyleControl = (base, { isFocused, isSelected, isMulti }) => {
  const control = isMulti ? {
    paddingLeft: 0,
    height: 'auto',
    minHeight: '4.5rem', // default height on react-select
  } : {}

  return {
    ...base,
    ...styleOption(isFocused, isSelected),
    ...control,
  }
}

const getStyleOption = (base, { isFocused, isSelected, data, options }) => {
  const [firstOption] = options

  return {
    ...base,
    ...styleOption(isFocused, isSelected),
    // first option doesn't have border-top
    borderTop: data.value === firstOption.value ? 0 : '.1rem solid #ccc',
  }
}

const getComponents = ({ isMulti, hideDropdown, options, hideEmptyMenu }) => {
  const indicatorComponent = !isMulti ? {
    IndicatorSeparator: null,
  } : {}
  const dropdownComponent = hideDropdown ? {
    DropdownIndicator: null,
  } : {}
  const menuComponent = hideEmptyMenu && options.length === 0 ? {
    Menu: () => null,
  } : {}
  return {
    ...indicatorComponent,
    ...dropdownComponent,
    ...menuComponent,
  }
}

export const Select: FunctionComponent<SelectPropType> =
({ className, labelClassName, fieldClassName, label, hideLabel, name, required, disabled, hasError, filterConfig, ...props }) => {
  const overrideComponents = getComponents(props as any)
  const enableRequired = !disabled && required

  let selectRef = null
  const setSelectRef = ref => {
    if (enableRequired) {
      selectRef = ref
    }
  }

  return (
    <div className={className}>
      {!hideLabel && (
        <label
          htmlFor={name}
          className={classnames(styles.select__label, labelClassName)}
        >
          {label}
        </label>
      )}
      <ReactSelect
        {...props}
        aria-label={label}
        name={name}
        inputId={name}
        className={classnames(styles.select__field, fieldClassName, { [styles.select__invalid]: hasError })}
        styles={{
          control: getStyleControl,
          option: getStyleOption,
          indicatorsContainer: base => ({
            ...base,
            border: 0,
          }),
          dropdownIndicator: base => ({
            ...base,
            color: '#13294b',
          }),
        } as any}
        components={overrideComponents}
        ref={setSelectRef}
        isDisabled={disabled}
        filterOption={createFilter(filterConfig)}
      />
      {enableRequired && (
        <input
          tabIndex={-1}
          autoComplete="off"
          className={styles.select__hidden}
          value={props.value || ''}
          onFocus={() => (selectRef as any)?.focus()}
          required={required}
          onChange={() => {}}
        />
      )}
    </div>
  )
}

Select.defaultProps = {
  className: '',
  labelClassName: '',
  fieldClassName: '',
  options: [],
  onChange: () => {},
  name: 'tableFilter',
  defaultValue: null,
  placeholder: '',
  isMulti: false,
  required: false,
  disabled: false,
  value: undefined,
  hideLabel: false,
  hideEmptyMenu: false,
  hideDropdown: false,
  hasError: false,
  filterConfig: {
    ignoreCase: true,
    ignoreAccents: true,
    matchFrom: 'any',
    stringify: option => `${option.label} ${option.value}`,
    trim: true,
  },
}
