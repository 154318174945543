import React from 'react'
import { HashLink } from 'react-router-hash-link'
import classNames from 'classnames'
import { node, oneOf, string } from 'prop-types'
import { CloseCross, Edit, TickEtapes, Step } from '@mmb/ui-components'
import { FromPage, trackOnClick } from '@core/analytics'

import styles from './VerticalStep.module.css'

VerticalStep.propTypes = {
  label: string.isRequired,
  children: node,
  state: oneOf([
    'inProgress',
    'error',
    'success',
    'notStarted',
  ]),
  nextStateStep: oneOf([
    'inProgress',
    'error',
    'success',
    'notStarted',
  ]),
  className: string,
  barClassName: string,
  labelClassName: string,
  contentClassName: string,
  anchor: string,
}

VerticalStep.defaultProps = {
  children: null,
  state: 'notStarted',
  nextStateStep: 'notStarted',
  className: '',
  barClassName: '',
  contentClassName: '',
  labelClassName: '',
  anchor: null,
}

const translateState = {
  notStarted: 'pas démarré',
  inProgress: 'en cours',
  error: 'en erreur',
  success: 'validé',
}

export function VerticalStep({ label, anchor, children, state, nextStateStep, className, barClassName, labelClassName, contentClassName, ...props }: any) {
  const icon = (
    <div
      className={classNames(
        styles.verticalStep__state,
        { [styles.verticalStep__stateNotStarted]: state === 'notStarted' },
        { [styles.verticalStep__stateInProgress]: state === 'inProgress' },
        { [styles.verticalStep__stateError]: state === 'error' },
      )}
      aria-label={`${label} ${translateState[state]}`}
    >
      {state === 'inProgress' && (<Edit className={styles.verticalStep__iconEdit} />)}
      {state === 'success' && (<TickEtapes className={styles.verticalStep__iconTickEtapes} />)}
      {state === 'error' && (<CloseCross className={styles.verticalStep__iconError} />)}
    </div>
  )
  const stepLabelClassName = classNames(
    styles.verticalStep__label,
    { [styles.verticalStep__labelNotStarted]: state === 'notStarted' },
    labelClassName,
  )
  return (
    <Step
      className={classNames(
        styles.verticalStep,
        { [styles.verticalStep__notStarted]: state === 'notStarted' },
        className,
      )}
      labelClassName={stepLabelClassName}
      barClassName={classNames(
        styles.verticalStep__bar,
        { [styles.verticalStep__barNotStarted]: state === 'notStarted' || nextStateStep === 'notStarted' },
        barClassName,
      )}
      contentClassName={classNames(
        styles.verticalStep__complement,
        contentClassName,
      )}
      icon={icon}
      label={anchor ? (
        <HashLink
          smooth
          className={stepLabelClassName}
          to={anchor}
          onClick={trackOnClick('step vertical', FromPage.SAISIE, label)}
        >{label}
        </HashLink>) : label}
      {...props}
    >
      {children}
    </Step>
  )
}
