import React, { FunctionComponent } from 'react'

import { Button } from '@mmb/ui-components'
import { FromPage, trackOnClick } from '@core/analytics'

import { useAssuranceParcours } from './AssuranceParcours.hooks'

import styles from './AssuranceParcours.module.css'
import { AssuranceUI } from '../../../../model/assurance/AssuranceUI'

export const AssuranceParcours: FunctionComponent<{ idPersonne: string, idDossier: string, assuranceUI: AssuranceUI }> =
({ idPersonne, idDossier, assuranceUI }) => {
  const { createAssurance, loading, hasPermissionParcoursAssurance } = useAssuranceParcours(
    { idPersonne, id: idDossier, assuranceUI })
  return (
    <div className={styles.assurance__parcours}>
      <p>L'emprunteur est éligible à l'assurance groupe. Vous pouvez dès maintenant lancer le parcours assurance.</p>
      <Button
        className={styles.assurance__button}
        onClick={() => {
          trackOnClick('bouton lancer parcours assurance', FromPage.DETAIL, 'validation parcours assurance')
          createAssurance()
        }}
        disabled={loading || !hasPermissionParcoursAssurance}
        loading={loading}
        type="button"
      >
        Lancer le parcours assurance
      </Button>
    </div>
  )
}
