import React, { useContext } from 'react'
import Pluralize from 'react-pluralize'
import { isNil } from 'lodash'
import { useNavigate } from '@core/routing'

import { Button, Plus } from '@mmb/ui-components'
import { searchContext } from '@core/partenaire-common'
import { Panel, Separator } from '@core/partenaire-ui'
import { FromPage, trackOnChange, trackOnClick } from '@core/analytics'
import { useIdentite } from '@core/partenaire-profil'

import { Filter } from '../Filter'
import { List } from '../List'

import styles from './Dossiers.module.css'
import { useDossiers } from './Dossiers.hooks'

export function Dossiers() {
  const { identifiant } = useIdentite()
  trackOnChange('Dossiers', identifiant)

  const navigate = useNavigate()
  const { totalCount: [totalCount] } = useContext(searchContext)
  const hasPermissionDossierWrite = useDossiers()

  const numberOfDossiers = !isNil(totalCount) && `(${totalCount})`
  return (
    <div className={styles.dossiers}>
      <Panel className={styles.dossiers__panel}>
        <Filter className={styles.dossiers__panelContent} />
      </Panel>

      <div className={styles.dossiers__body}>
        <div className={styles.dossiers__bandeau}>
          <div className={styles.dossiers__bandeauIsLeft}>
            <h2 id="dossiers_table_description">
              <Pluralize singular="Dossier" zero="Dossier" count={totalCount} showCount={false} /> {numberOfDossiers}
            </h2>
            <Button
              className={styles.dossiers__button}
              disabled={!hasPermissionDossierWrite}
              onClick={() => {
                trackOnClick('boutton créer dossier', FromPage.DOSSIERS, 'saisie de dossier')
                navigate('/app/dossiers/saisie')
              }}
            >
              <Plus className={styles.dossiers__plus} />
              Créer un dossier
            </Button>
          </div>
        </div>
        <Separator className={styles.dossiers__separator} />
        <List />
      </div>
    </div>
  )
}
